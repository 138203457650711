<template>
  <div class="container">
    <a-page-header title="操作员管理" :backIcon="false"></a-page-header>
    <div class="wrapper">
      <!-- <a-form layout="inline" :form="form">
        <a-form-item label="真实姓名">
          <a-input type="text" v-decorator="['real_name']" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="handleSearch">搜索</a-button>
          <a-button style="margin-left:5px;" @click="handleReset">重置</a-button>
        </a-form-item>
      </a-form> -->
      <a-table
        :columns="columns"
        row-key="id"
        :data-source="tableData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange">
          <template slot="operate" slot-scope="text, item">
            <a-popconfirm v-if="userInfo.role_id == 0" title="确定要重置密码吗？" placement="bottomRight" ok-text="确定" cancel-text="取消" @confirm="resetPassword(item)">
              <a-button type="danger" icon="reload" size="small">重置密码</a-button>
            </a-popconfirm>
          </template>
        </a-table>
    </div>
  </div>
</template>

<script>

import { userList, userInfo } from '@/utils/api'
import { mapState } from 'vuex'
const columns = [
  {
    title: '登录账号',
    dataIndex: 'username'
  },
  {
    title: '项目名称',
    dataIndex: 'task_name'
  },
  
  {
    title: '操作',
    dataIndex: 'operate',
    scopedSlots: { customRender: 'operate' },
  }
]
export default {
  name: 'OperatorList',
  data() {
    return {
      form: this.$form.createForm(this),
      tableParams: {},
      columns,
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showTotal: total => `共${total}条数据`
      },
      loading: false
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    resetPassword(item) {
      userInfo({
        switch: 3,
        id: item.id,
        password: '123456'
      }).then((res) => {
        if (res.error == 0) {
          this.$success({
            title: '提示',
            content: (
              <div>
                <p>密码已重置为：123456。</p>
                <p>为了保护用户的账号安全，请提示用户尽快修改密码！</p>
              </div>
            ),
            okText: '确定'
          })
        }
      })
    },
    handleSearch() {
      this.form.validateFields((err, values) => {
        this.tableParams = { ...values }
        const pagination = { ...this.pagination }
        pagination.current = 1,
        this.pagination = pagination
        this.getTableData()
      })
    },
    handleReset() {
      this.form.setFieldsValue({
        real_name: '',
        company_name: '',
        status: ''
      })
      this.handleSearch()
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getTableData()
    },
    getTableData() {
      this.loading = true
      const pagination = { ...this.pagination }
      userList({
        role_id: 3,
        pageCount: pagination.pageSize,
        page: pagination.current,
        ...this.tableParams
      }).then(res => {
        if (pagination.current == 1){
          pagination.total = res.count
          this.pagination = pagination
        }
        this.tableData = res.user
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px 24px;
}
</style>
